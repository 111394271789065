<script lang="ts">
import { useConfigStore } from '~/store'

export default {
    name: 'EmptyLayout',
    data: () => ({
    }),
    computed: {
        loading() {
            return useConfigStore().globalLoading
        },
    },
    mounted() {
        useConfigStore().setGlobalLoading(false)
    },
}
</script>

<template>
    <div>
        <MenuGlobalLoader :loading="loading">
            <template #loader>
                <svg viewBox="25 25 50 50">
                    <circle
                        class="loader-path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="#636363"
                        stroke-width="2"
                    />
                </svg>
            </template>
            <template #logo>
                <img
                    class="mx-auto"
                    src="@nuxtcommon/assets/images/logo.png"
                    alt="Pildom"
                >
            </template>
        </MenuGlobalLoader>

        <slot />
        <Toaster />
    </div>
</template>
